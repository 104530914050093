.ant-btn {
    font-weight: 600;

    .sm({
        min-height: 40px;
        min-width: 40px !important;
    });


    &.ant-btn-square {
        padding: 0 10px;
    }
}

.ant-input-search {
    .ant-input-affix-wrapper {
        .sm({
            height: 40px;
        });
    }
}
