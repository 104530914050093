// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
.wrapper {
  height: 5px;
  border-radius: 8px;
  background-color: #f5f5f5;
  margin: 4px 0;
  overflow: hidden;
  box-shadow: -1px 1px 1px #000b550d inset;
}

.inner {
  height: 100%;
}
