.overlay(@pos: 0, @type: absolute) {
    position: @type;
    left: @pos;
    right: @pos;
    bottom: @pos;
    top: @pos;
}

.flexCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.textOverflow() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.addButtonVariant(@background-color, @text-color: white) {
    box-shadow: 0 2px 5px rgba(@background-color, 0.4);
    border: none;
    background: @background-color;
    color: @text-color;
    &:hover,
    &:focus {
        background: lighten(@background-color, 5%);
        box-shadow: 0 2px 5px rgba(@background-color, 0.4);
        color: @text-color;
    }
}

.addButtonOutlined(@main-color, @text-color: @main-color) {
    background: white;
    border: 1px solid @main-color;
    color: @text-color;
    &:hover,
    &:focus {
        opacity: 0.8;
    }
}

.customizeScrollbars(@size: 5px, @thumb: @border-color-base, @background: #fff, @visibility: visible) {
    scroll-behavior: smooth;

    // Let's get this party started
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: @size;
        height: @size;
    }

    // Track
    &::-webkit-scrollbar-track {
        background: #fff;
        box-shadow: inset 1px 1px 0 rgba(black, 0.05);
        border-radius: 10px;
        background: @background;
        visibility: @visibility;
    }

    &:hover::-webkit-scrollbar-track {
        visibility: visible;
    }

    // Handle
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: @thumb;
        visibility: @visibility;
    }

    &:hover::-webkit-scrollbar-thumb {
        visibility: visible;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
    }
}

.fancyDebug(@background-color: #fff) when (@debug-mode) {
    background: @background-color;
    border-radius: @border-radius;
    padding: 1rem;
    margin: 5px;
}

.tagVariantBase() {
    font-size: 12px;
    font-weight: 600;
    padding: 0 0.4rem;
    display: inline-flex;
    align-items: center;
    line-height: 12px;
    min-height: 22px;
    white-space: nowrap;
    border-radius: 8px;
}

.tagVariant(@base-color: #ccccd1, @text-color: white) {
    .tagVariantBase;
    transition: background-color 0.15s ease;
    color: @text-color;
    border: 1px solid @base-color;
    background: @base-color;
    box-shadow: 0 5px 4px -2px rgba(@base-color, 0.4);
}

.tagOutlined(@base-color: black, @text-color: darken(@base-color, 10%)) {
    .tagVariantBase;
    color: @text-color;
    border: 1px solid @base-color;
    background: white;
}

.tinyShadows() {
    box-shadow: 0 1px 0 rgba(#3f5e75, 0.02), 0 1px 4px rgba(#3f5e75, 0.05);
}

.defaultShadows() {
    box-shadow: 0 4px 14px -4px #3f5e7509, 0 2px 15px -5px #3f5e7540;
}

.card(@padding:1rem) {
    .tinyShadows();
    padding: @padding;
    border-radius: @border-radius;
    background: #fff;
}

.spaceBetween() {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.addTransition() {
    transition: @transition;
}

.gridCards(@min-width: 320px) {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(@min-width, 1fr));
}

// Customize Editor height (no another way exists)
.setEditorHeight(@height:160px, @max-height: @height) {
    :global {
        .bf-content {
            max-height: @max-height;

            .public-DraftEditor-content {
                min-height: @height; // customize editor height (no another way exists)
            }
        }
    }
}

.invisible(@opacity:0.65) {
    opacity: @opacity;
    .addTransition;

    &:hover {
        opacity: 1;
    }
}

.size(@width; @height:@width) {
    width: @width;
    height: @height;
}

// Add fontawesome icon
.addIcon(@icon, @color: inherit) {
    &::before {
        font-family: @fa-font-family;
        content: @icon;
        color: @color;
        padding-right: 5px;
    }
}

.widget(@padding:16px) {
    .tinyShadows();
    padding: @padding;
    background-color: @background-alt;
    border-radius: @border-radius;
}

.widgetAlt(@padding:16px 24px) {
    .tinyShadows();
    padding: @padding;
    border-radius: @border-radius;
    background: @background-alt;
    border: 1px solid @border-color-alt;
}

.media-breakpoint(@maxWidth; @rules) {
    @media only screen and (max-width: @maxWidth) {
        @rules();
    }
}

.media-breakpoint-min(@minWidth; @rules) {
    @media only screen and (min-width: @minWidth) {
        @rules();
    }
}

.xxl(@rules) {
    .media-breakpoint(@breakpoint-xxl*1px; @rules)
}


.xl(@rules) {
    .media-breakpoint(@breakpoint-xl*1px; @rules)
}

.lg(@rules) {
    .media-breakpoint(@breakpoint-lg*1px; @rules)
}

.md(@rules) {
    .media-breakpoint(@breakpoint-md*1px; @rules)
}

.sm(@rules) {
    .media-breakpoint(@breakpoint-sm*1px; @rules)
}

.xs(@rules) {
    .media-breakpoint(@breakpoint-xs*1px; @rules)
}

.xxl-min(@rules) {
    .media-breakpoint-min((@breakpoint-xxl + 1)*1px; @rules)
}


.xl-min(@rules) {
    .media-breakpoint-min((@breakpoint-xl + 1)*1px; @rules)
}

.lg-min(@rules) {
    .media-breakpoint-min((@breakpoint-lg + 1)*1px; @rules)
}

.md-min(@rules) {
    .media-breakpoint-min((@breakpoint-md + 1)*1px; @rules)
}

.sm-min(@rules) {
    .media-breakpoint-min((@breakpoint-sm + 1)*1px; @rules)
}

.xs-min(@rules) {
    .media-breakpoint-min((@breakpoint-xs + 1)*1px; @rules)
}

.hover(@rules) {
    &:hover {
        @media only screen and (hover: hover) and (pointer: fine) {
            @rules();
        }
    }

}
