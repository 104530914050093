// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
// BadgeCounter
.wrapper {
    user-select: none;

    :global {
        .ant-badge-count {
            font-weight: 600;
            color: #5c6c78;
            background: white;
            box-shadow: 0 5px 4px -2px rgba(#0c30490d, 0.05);
        }
    }
}

.prefix:not(:empty) {
    padding-right: .3rem;
}
