// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
// GridCards
.wrapper {
    display: grid;
    grid-gap: 1.1rem;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));


    .xs({
        grid-template-columns: 1fr !important;
    });

  &.card {
    .card();
    border: 1px solid @border-color-alt;
    box-shadow: 0 1px 0 rgb(63 94 117 / 2%), 0 1px 4px rgb(63 94 117 / 5%);
    grid-template-columns: repeat(2, 1fr);

    .md({
      grid-template-columns: 1fr;
    })
  }

  &.loading {
    &.minHeightDefault {
      min-height: 200px;
    }

    &.minHeightSmall {
      min-height: 50px;
    }
  }



  &.col-1 {
    grid-template-columns: 1fr;
  }


  &.col-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;

    .xl({
      grid-template-columns: 1fr;
    })
  }


  &.large {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }

  &.normal {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  &.small {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  &.xs {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  }

  &.xxs {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }


}


