// Global variables

// Font preferences
@font-base: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
@weight-bold: 600;

// Main
@black-color: #1f2d37;
@white-color: #fff;
@term-color: rgba(@black-color, 0.5);
@site-background: #f5f8fb;
@stripped-color: #f9fbfd; // new

// Card alt
@background-alt: #f9fbfd;
@border-color-alt: #dfebf4;

// Status
@success-color: #78cd62;
@info-color: #40a9ff;
@danger-color: #df6060;
@warning-color: #ffc53d;

@muted-color: #848b92;
@gray-color: #252525;

@gray-color: #acacbc;

// Tabs
@tabs-active-color: @link-color;
@tabs-highlight-color: @link-color;
@tabs-ink-bar-color: @link-color;

// Borders
@border-color: #f8f9fa;
//@border-color-alt: darken(@border-color, 20%);
@border-radius: 8px;
@border-radius-lg: 12px;
@border-radius-sm: 6px;

// Cards
@card-border-radius: 12px;
@action-btn-size: 32px;

// Tags
@tag-variants: default #b4c4d0, black #222, pink #ff57a3, warning @warning-color, info @info-color,
success @success-color, danger @danger-color, mint #50d8e3, primary @primary-color;

// Browser
@autocomplete-color: @black-color;

// AppBaseLayout
@header-height: 60px;
@menu-width-expanded: 236px;
@menu-width-collapsed: 80px;

@transition: all 0.18s ease-in-out;

// Debug
@debug-mode: false;

@primary-color: #78cd62; // primary color for all components [#78cd62 | green]
@link-color: #0078f0; // link color
@success-color: #78cd62; // success state color
@warning-color: #ffc53d; // warning state color
@error-color: #df6060; // error state color
@turquoise-color: #006ba7;
// Statuses
@pending-color: @purple-5;
@inwork-color: @blue-5;

@font-size-base: 14px; // major text font size
@heading-color: #1f2d37; // heading text color
@text-color: #1f2d37; // major text color
@text-color-secondary: #848b92; // secondary text color
@disabled-color: #c9d2da; // disable state color
@border-radius-base: 8px; // major border radius
@border-color-base: #dfebf4; // major border color
@border-color-split: #f3f4f7;

//@disabled-color: #acc8dc;
@disabled-bg: #f3f4f7;
//@disabled-active-bg: red;
//@disabled-color-dark: red;

// shadows
@box-shadow-base: 0px 1px 0px rgba(#dfebf4, 0.2), 0px 3.5px 5.5px rgba(#dfebf4, 0.2), 0 1px 8px #002e5212;
@btn-shadow: 0 2px 0 rgba(#dfebf4, 0.015);
@btn-primary-shadow: 0 3px 8px rgba(@primary-color, 0.3);
@btn-text-shadow: 0 -1px 0 rgba(white, 0.12);

// Backgrounds
@background-color-light: #f8f9fa;
@descriptions-bg: #f8f9fa;

// Table
@table-header-bg: #fff;
@table-body-sort-bg: #f8f9fa;

// Tag
@tag-default-bg: #fff;

// Input
@input-placeholder-color: #848b92;

///CUSTOM
@muted-color: #848b92;
@muted-text: #75a3d1;
@gray-color: #acacbc;
@info-color: #40a9ff;
@danger-color: #df6060;
@success-hover-color: #9dd98b;
@turquoise-color: #006ba7;

// Recommend wrapper width (based on Editor controls)
// Do not forget update this variable when extend Editor controls!
@editor-wrapper-width: 440px;
@editor-default-min-height: 160px;

// Roles
@user-color: #7fd778;
@customer-color: #d768ab;
@writer-color: #32b4d0;

@color-gray: #a5a5b7;

/**
    Example of use:
    @media @tablet {
      footer {
        width: 940px;
      }
    }
 */
@laptop: ~"only screen and (max-width: 1400px)";
@tablet: ~"only screen and (max-width: 960px)";
@mobile: ~"only screen and (max-width: 780px)"; // not covered

@fa-font-family: "Font Awesome 6 Pro";

@breakpoint-xs: 576;
@breakpoint-sm: 768;
@breakpoint-md: 992;
@breakpoint-lg: 1200;
@breakpoint-xl: 1400;
@breakpoint-xxl: 1920;

:root {
    --black-color: @black-color;
    --text-color: @text-color;
    --success-color: @success-color;
    --success-hover-color: @success-hover-color;
    --primary-color: @primary-color;
    --warning-color: @warning-color;
    --muted-color: @muted-color;
    --gray-color: @gray-color;
    --info-color: @info-color;
    --danger-color: @danger-color;
    --border-color: @border-color-base;
    --turquoise-color: @turquoise-color;
    --link-color: @link-color;

    // Roles
    --user-color: @user-color;
    --customer-color: @customer-color;
    --writer-color: @writer-color;

    --xs: @breakpoint-xs;
    --sm: @breakpoint-sm;
    --md: @breakpoint-md;
    --lg: @breakpoint-lg;
    --xl: @breakpoint-xl;
    --xxl: @breakpoint-xxl;
}
