// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.wrapper {
  position: relative;
}

.count {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 2px;
  right: 5px;
  border-radius: 50%;
  background: #D63636;
  box-shadow: 0 3px 8px rgba(139, 82, 82, 0.25);
}
