// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.core-search-engine{


    form {
        flex-flow: column;
    }

    &__panel {
      display: flex;
      justify-content: space-between;

      .ant-btn-square {
        width: 32px;
        height: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__controls {
      display: flex;
      gap: 5px 0;
      flex-wrap: wrap;

      .ant-row.ant-form-item {
        &:first-child {
          @media only screen and (max-width: 576px) {
            width: calc(100% - 105px);
          }
        }
      }
    }

    &__main {
      margin-top: 16px;
      display: flex;
      flex-wrap: nowrap; // children & extraControls
      gap: 10px 16px;
      padding: 2px;
    }

    &__panel-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__children {
      max-width: 100%;
      flex: 1; // greedy element
    }

    &__drawer {
      .ant-drawer-close {
        display: none;
      }

      .ant-drawer-header {
        padding: 16px 12px;
      }

      .ant-drawer-content {
        border-radius: 8px 0 0 8px;
      }

      .ant-drawer-body {
        padding: 12px;
        display: flex;
        flex-direction: column;
      }
    }

    &__drawer-form {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
    }

    &__drawer-button {
      min-height: 40px;
      width: 100%;
    }

    &__extra {
      margin-top: 15px;
    }

    &__extra-controls {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      gap: 10px;
      width: 250px;
      position: sticky;
      top: 70px;

      hr {
        width: 100%; // coz: flex
      }

      .ant-row.ant-form-item-row {
        display: flex;
        flex-direction: column;

        .ant-form-item-label {
          text-align: left;
        }
      }

      .ant-row.ant-form-item {
        margin: 0; // less space
        flex-wrap: wrap; // coz: inline form layout (search engine)

        // Condensed labels
        .ant-form-item-label {
          min-width: 100%;
          display: block;
          padding: 0;
          text-align: left; // coz: inline form

          label {
            font-size: 12px;
            height: unset;
          }
        }
      }
    }

    &__bottom {
      margin-top: 30px;
    }

    &--extra-controls {
      .core-search-engine__children {
        max-width: calc(100% - 265px);
      }
    }
}


.core-search-engine-mobile-menu {
  border-right: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px !important;

  &.ant-menu {
    border-right: none;

    &::before, &::after {
      display: none;
    }
  }


  > li {
    height: 22px !important;
    line-height: 22px !important;
    text-align: right;
    padding: 0 !important;
    font-size: 14px;
    background-color: transparent !important;

    &::after {
      display: none ;
    }




    i {
      margin-right: 5px;
    }
  }
}

.core-search-engine-drawer-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;

  .ant-row.ant-form-item-row {
    flex-direction: column;
  }

  .ant-form-horizontal .ant-form-item-control {
    flex: auto;
  }

  .ant-form-item-label {
    text-align: left;
  }
}

.core-search-engine-sort-popover {
  &__select-wrap {
    .ant-form-item-row {
      flex-direction: row;
      @media only screen and (max-width: 768px) {
        flex-direction: column;

        .ant-form-item-label {
          text-align: left;
        }
      }
    }

  }

  &__select {
    min-width: 150px;
  }

  &__btns-wrap {
    .ant-form-item-row {
      display: flex;

      @media only screen and (max-width: 768px) {
        display: block;
      }
    }

  }

  &__btns {
    width: 100%;
  }

  &__btn {
    white-space: nowrap;
    @media only screen and (max-width: 768px) {
      width: 50%;
      height: 40px;
      line-height: 40px;
    }
  }
}

.core-search-engine-drawer-button {
  width: 100%;
  min-height: 40px;
}

.core-search-engine-drawer-extra-controls-button {
  width: 100%;
  margin-bottom: 16px;
  min-height: 40px;
}
