// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
// DateTime
.wrapper {
    white-space: nowrap;
}

.fromNow {
    font-weight: 600;
    color: var(--success-color, @success-color);

    &.expired {
        color: var(--danger-color, @danger-color);
    }
}

.typeDefault {

}

.typeSecondary {
    color: @muted-color;

    .divider {
        display: none;
    }

    .fromNow {
        display: block;
    }
}
