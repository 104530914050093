/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/**
    Example of use:
    @media @tablet {
      footer {
        width: 940px;
      }
    }
 */
:root {
  --black-color: #1f2d37;
  --text-color: #1f2d37;
  --success-color: #78cd62;
  --success-hover-color: #9dd98b;
  --primary-color: #78cd62;
  --warning-color: #ffc53d;
  --muted-color: #848b92;
  --gray-color: #acacbc;
  --info-color: #40a9ff;
  --danger-color: #df6060;
  --border-color: #dfebf4;
  --turquoise-color: #006ba7;
  --link-color: #0078f0;
  --user-color: #7fd778;
  --customer-color: #d768ab;
  --writer-color: #32b4d0;
  --xs: 576;
  --sm: 768;
  --md: 992;
  --lg: 1200;
  --xl: 1400;
  --xxl: 1920;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
