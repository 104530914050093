// Default form styles
form.default-form {
    .ant-input-number,
    .ant-input,
    .ant-select {
        min-width: unset;
        width: 100%;
        max-width: 100%;
    }
}

.ant-form {
    .ant-row {
        max-width: 100%;
    }
}

.ant-form-item-extra {
    color: #7e96ad;
    font-size: 12px;
    padding: 3px 3px 0;
}

.ant-form-item-explain {
    font-size: 12px;
    padding-top: 1px;
}
