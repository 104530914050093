// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
.wrapper {
    //.customizeScrollbars(1px, @primary-color);
    margin-top: 1rem;
    overflow: auto;
    font-size: .8rem;
    line-height: 1.2;
    word-break: break-word;

    code {
        font-size: 10px;
    }
}

.panel {
    white-space: pre-wrap;
    font-size: 0.75rem;
}
