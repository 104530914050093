// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.logo {
    .flexCenter();
    font-size: 1rem;
    font-weight: 700;
    position: relative;
    margin: auto;
    padding: .75rem;
}

.image {
    height: 30px;
}

.version {
    font-family: sans-serif;
    font-size: 10px;
    position: absolute;
    top: .8rem;
    right: 1.2rem;
    opacity: .2;
}
