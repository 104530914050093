// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.wrapper {
  flex: 1;
  height: 60vh;
  align-items: center;
  justify-content: center;

  :global {
    .ant-result-icon {
      margin-bottom: 0;
      img {
        max-width: 300px;
      }

    }

    .ant-result-extra {
      margin-top: 40px;
    }
  }
}

.title {
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  color: @primary-color;
  margin-bottom: 16px;
}


.subTitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: @muted-color;
}

.container {
  flex: 1;
  padding: 0;
}

