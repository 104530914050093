// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
.wrapper {
  :global {
    .core-search-engine-date-popover {
      &__button {
        &--active {
          color: @primary-color;
          border-color: @primary-color;
        }
      }
    }
  }
}
