/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/**
    Example of use:
    @media @tablet {
      footer {
        width: 940px;
      }
    }
 */
:root {
  --black-color: #1f2d37;
  --text-color: #1f2d37;
  --success-color: #78cd62;
  --success-hover-color: #9dd98b;
  --primary-color: #78cd62;
  --warning-color: #ffc53d;
  --muted-color: #848b92;
  --gray-color: #acacbc;
  --info-color: #40a9ff;
  --danger-color: #df6060;
  --border-color: #dfebf4;
  --turquoise-color: #006ba7;
  --link-color: #0078f0;
  --user-color: #7fd778;
  --customer-color: #d768ab;
  --writer-color: #32b4d0;
  --xs: 576;
  --sm: 768;
  --md: 992;
  --lg: 1200;
  --xl: 1400;
  --xxl: 1920;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../../media/fonts/open-sans-v28-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-500 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../../media/fonts/open-sans-v28-latin-500.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-500.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("../../media/fonts/open-sans-v28-latin-600.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../../media/fonts/open-sans-v28-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* open-sans-800 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("../../media/fonts/open-sans-v28-latin-800.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("../../media/fonts/open-sans-v28-latin-800.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.85;
}
.clickable {
  cursor: pointer;
}
.tiny-shadows {
  box-shadow: 0 1px 0 rgba(63, 94, 117, 0.02), 0 1px 4px rgba(63, 94, 117, 0.05);
}
.default-shadows {
  box-shadow: 0 4px 14px -4px #3f5e7509, 0 2px 15px -5px #3f5e7540;
}
.card {
  box-shadow: 0 1px 0 rgba(63, 94, 117, 0.02), 0 1px 4px rgba(63, 94, 117, 0.05);
  padding: 1rem;
  border-radius: 8px;
  background: #fff;
}
.space-between {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.justify-start {
  justify-content: flex-start;
}
.multiplier {
  font-weight: 600;
  font-style: normal;
  font-size: 0.7rem;
  border-radius: 4px;
  opacity: 0.5;
  padding: 0 1px;
  width: 35px;
  justify-content: center;
  align-self: center;
}
.meta {
  background: rgba(255, 0, 0, 0.1);
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 20px;
}
.opacity-5 {
  opacity: 0.5;
}
.ant-card {
  border: 1px solid #dfebf4;
  display: flex;
  flex-flow: column;
  box-shadow: 0 1px 0 rgba(63, 94, 117, 0.02), 0 1px 4px rgba(63, 94, 117, 0.05);
}
.ant-card .ant-card-body {
  flex: 1;
}
.ant-card .ant-card-head {
  background: #f9fbfd;
  border-bottom-color: #dfebf4;
}
.ant-card .ant-card-head .ant-card-head-title {
  overflow: visible;
}
.ant-card .ant-card-actions {
  background: #f9fbfd;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
  display: flex;
}
.ant-card .ant-card-actions li {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-btn {
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  .ant-btn {
    min-height: 40px;
    min-width: 40px !important;
  }
}
.ant-btn.ant-btn-square {
  padding: 0 10px;
}
@media only screen and (max-width: 768px) {
  .ant-input-search .ant-input-affix-wrapper {
    height: 40px;
  }
}
.ant-table {
  border-radius: 5px;
  background: #fff;
  border: 1px solid #dfebf4;
  overflow: auto;
}
.ant-table .ant-table-row:last-child td {
  border: none;
}
.ant-table-body {
  overflow-x: auto !important;
}
form.default-form .ant-input-number,
form.default-form .ant-input,
form.default-form .ant-select {
  min-width: unset;
  width: 100%;
  max-width: 100%;
}
.ant-form .ant-row {
  max-width: 100%;
}
.ant-form-item-extra {
  color: #7e96ad;
  font-size: 12px;
  padding: 3px 3px 0;
}
.ant-form-item-explain {
  font-size: 12px;
  padding-top: 1px;
}
.ant-tabs {
  overflow: visible;
}
.ant-tag {
  font-weight: 600;
}
.ant-select:not([style*="width"]) {
  min-width: 160px;
}
.ant-select-multiple .ant-select-selection-item-content > div + div {
  display: none;
}
.ant-select-multiple .ant-select-selection-item {
  background: white;
  border: none;
}
.ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content > div {
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.ant-select-dropdown {
  box-shadow: 0 4px 14px -4px #3f5e7509, 0 2px 15px -5px #3f5e7540;
}
.ant-transfer-list {
  background: rgba(255, 255, 255, 0.5);
}
.ant-timeline {
  padding-left: 3px;
}
.ant-timeline .ant-timeline-item-label {
  margin-left: 25px;
  position: relative;
  top: -6px;
}
.ant-timeline .ant-timeline-item-head {
  background-color: #f5f8fb;
}
.ant-timeline .ant-timeline-item-tail {
  border-left: 1px solid #dfebf4;
}
.ant-timeline .ant-timeline-item:last-child .ant-timeline-item-tail {
  display: none;
}
.ant-tooltip-inner {
  max-height: 200px;
  overflow-y: auto;
}
.ant-empty .ant-empty-image {
  height: fit-content;
}
.ant-empty .ant-empty-description {
  color: #848b92;
  opacity: 0.75;
  font-size: 0.7rem;
  font-weight: 600;
}
.ant-empty svg {
  height: 60px;
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: block;
    margin-left: 0;
  }
  .ant-pagination-options > .ant-select {
    width: 100%;
  }
}
.ant-pagination > li {
  margin-top: 15px;
}
/**
    Basic styles
 */
html,
body {
  height: 100%;
  margin: 0;
  background: #f5f8fb;
}
body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
h1 {
  margin-bottom: 1rem;
}
h2,
h3,
h4,
h5,
h6 {
  margin: 1rem 0 0.5rem;
}
ul {
  padding: 0;
  margin: 0;
}
a {
  color: #0078f0;
}
.ant-tooltip a {
  color: white;
}
a.active {
  color: #1890ff;
}
hr {
  opacity: 0.2;
}
label {
  user-select: none;
}
ul {
  list-style: none;
}
strong {
  font-weight: 600;
}
dd {
  margin: 0;
}
:focus {
  outline: none;
}
/**
    Browser styles
 */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #1f2d37 !important;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
hr {
  border: none;
  border-top: 1px solid #93bcda;
}
hr.double {
  padding: 1px 0;
  border-bottom: 1px solid #93bcda;
}
small {
  font-size: 0.85em;
}
#root {
  height: 100%;
}
.option-description {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  justify-content: space-between;
  font-size: 0.8rem;
}
.option-description div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  white-space: nowrap;
  gap: 7px;
}
.option-description dt {
  color: rgba(31, 45, 55, 0.5);
}
.ant-input-textarea-show-count::after {
  font-size: 11px;
  font-weight: 600;
  color: rgba(31, 45, 55, 0.5);
}
.grid-cards {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}
.grid-cards.grid-large {
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
}
.grid-cards.grid-normal {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.grid-cards.grid-small {
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}
.grid-cards.grid-xs {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.flex-grid {
  display: flex;
  gap: 20px;
  grid-gap: 20px;
}
.spin-fast {
  animation: fa-spin 0.3s linear infinite;
}
.dotTag[class] {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: unset;
  padding: 0 5px;
  line-height: 14px;
}
.ant-upload.ant-upload-drag {
  background: #f9fbfd;
}
.ant-btn.ghost-primary {
  color: #78cd62;
  padding: 4px 8px;
  border-color: #78cd62;
  height: auto;
  font-size: 12px;
}
.ant-btn.ghost-primary:hover {
  color: #40a9ff;
  border-color: #40a9ff;
}
@media only screen and (max-width: 1200px) {
  .ant-tabs {
    margin-left: -15px;
    margin-right: -15px;
  }
  .ant-tabs .ant-tabs-nav-wrap {
    white-space: normal !important;
    padding-bottom: 7px;
    scroll-behavior: smooth;
  }
  .ant-tabs .ant-tabs-nav-wrap::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 1px;
    height: 1px;
  }
  .ant-tabs .ant-tabs-nav-wrap::-webkit-scrollbar-track {
    box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background: #fff;
    visibility: visible;
  }
  .ant-tabs .ant-tabs-nav-wrap:hover::-webkit-scrollbar-track {
    visibility: visible;
  }
  .ant-tabs .ant-tabs-nav-wrap::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #78cd62;
    visibility: visible;
  }
  .ant-tabs .ant-tabs-nav-wrap:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  .ant-tabs .ant-tabs-nav-wrap::before,
  .ant-tabs .ant-tabs-nav-wrap::after {
    display: none !important;
  }
  .ant-tabs .ant-tabs-content-holder,
  .ant-tabs .ant-tabs-nav-list {
    padding-left: 15px;
    padding-right: 15px;
  }
  .ant-tabs .ant-tabs-tab-btn {
    white-space: nowrap;
  }
  .ant-tabs .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 24px;
  }
  .ant-tabs .ant-tabs-tab {
    padding: 8px 0 !important;
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 768px) {
  .ant-modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    overflow: auto;
  }
}
@media only screen and (max-width: 768px) {
  .ant-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100%;
    width: 100%;
    top: 0;
    width: 100% !important;
    max-width: 100% !important;
    padding-bottom: 0;
    margin: 0;
    transform-origin: 0 0 !important;
  }
}
@media only screen and (max-width: 768px) {
  .ant-modal .ant-modal-body {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .ant-modal.ant-zoom-enter,
  .ant-modal.ant-zoom-appear {
    opacity: 1 !important;
    animation: none !important;
    overflow: hidden;
  }
  .ant-modal.ant-zoom-enter .ant-modal-content,
  .ant-modal.ant-zoom-appear .ant-modal-content {
    animation: 0.25s fromDown !important;
  }
}
@media only screen and (max-width: 768px) {
  .ant-modal.ant-zoom-leave.ant-zoom-leave-active {
    overflow: hidden;
    animation: 0.2s fadeSuddenly !important;
  }
  .ant-modal.ant-zoom-leave.ant-zoom-leave-active .ant-modal-content {
    animation: 0.2s 0.05s toDown !important;
  }
}
@media only screen and (max-width: 768px) {
  .ant-modal-content {
    width: 100%;
    max-width: none;
    border-radius: 8px 8px 0 0;
  }
}
@keyframes fromDown {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes toDown {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}
@keyframes fadeSuddenly {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media only screen and (max-width: 768px) {
  .DateTimePickerDropdown .ant-picker-datetime-panel {
    display: block;
  }
  .DateTimePickerDropdown .ant-picker-time-panel .ant-picker-content {
    height: 64px;
  }
  .DateTimePickerDropdown .ant-picker-panel {
    width: 280px;
  }
  .DateTimePickerDropdown .ant-picker-footer {
    max-width: 100%;
  }
  .DateTimePickerDropdown .ant-picker-date-panel .ant-picker-content {
    width: 100%;
  }
  .DateTimePickerDropdown .ant-picker-content {
    display: block;
    padding: 0 12px;
  }
  .DateTimePickerDropdown .ant-picker-time-panel-column {
    display: flex;
    width: 100%;
    height: 38px;
    padding: 5px 0;
    overflow: hidden;
    overflow-x: auto;
  }
  .DateTimePickerDropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
    padding: 0 10px;
  }
  .DateTimePickerDropdown .ant-picker-time-panel-column:not(:first-child) {
    border-left: none;
  }
}
.SearchDateRangeDropdown .ant-picker-panel:last-child {
  width: 0;
}
.SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header {
  position: absolute;
  right: 0;
}
.SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-prev-btn,
.SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-view {
  visibility: hidden;
}
.SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-body {
  display: none;
}
@media (min-width: 768px) {
  .SearchDateRangeDropdown .ant-picker-panel:last-child {
    width: 280px!important;
  }
  .SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header {
    position: relative;
  }
  .SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-prev-btn,
  .SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-header .ant-picker-header-view {
    visibility: initial;
  }
  .SearchDateRangeDropdown .ant-picker-panel:last-child .ant-picker-body {
    display: block;
  }
}
.SearchDateRange .ant-picker-clear {
  opacity: 0 !important;
}
.SearchDateRange .ant-picker-range {
  width: 100%;
}
