// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
// DeadlineRemainingCountDown
.wrapper {
  color: var(--success-color, @success-color);

  &.expired {
    color: var(--danger-color, @danger-color);
    font-weight: 600;
  }
}
