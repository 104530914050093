// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.wrapper {
    display: grid;
    grid-template-areas: "header header" "nav main";
    grid-template-columns: @menu-width-expanded minmax(0, 1fr);

    &.compact {
      grid-template-columns: @menu-width-collapsed minmax(0, 1fr);
    }

    .sm({
        grid-template-areas: "header" "main";
        grid-template-columns: 1fr;
        display: block;
    });
}

.left {
    position: sticky;
    top: @header-height;
    grid-area: nav;
    background: @background-alt;
    z-index: 1;
    max-height: calc(100vh - @header-height);

    .sm({
      overflow-y: auto;
    });

    .sm-min({
        animation: fadeInLeft 0.4s ease 0.1s both;
    });

    .customizeScrollbars(2px);

    .navigation {
        display: flex;
        flex-flow: column nowrap;
        user-select: none;
    }
}

.header {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    z-index: 10;
    top: 0;
    height: @header-height;
    animation: fadeInDown 0.4s ease both;
    border-bottom: 1px solid #eff4f8;
    grid-area: header;
    background: #f9fbfd;
    user-select: none;

    // Header links
    a {
        .flexCenter;
    }

    .logo {
        cursor: pointer;
        color: @black-color;
        display: block;
    }

    .content {
        font-size: 1rem;
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding: 0 1rem;
    }

    .logoImage {
        justify-content: flex-start;
        padding: 0;
        margin: 0;
    }
}

.avatarImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main {
    padding: 10px;

    grid-area: main;

    .sm-min({
        animation: fadeInRight 0.4s ease 0.15s both;
    });

    .lg({
        padding: 15px;
    });

    .content {
        flex: 1;
        min-height: calc(100vh - 120px);
        margin-top: 0.5rem;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;

        .lg({
            margin: 0;
            padding: 0;
        });
    }
}

.footer {
    font-size: 11px;
    padding: 0.5rem 1rem;
    color: @black-color;
    user-select: none;

    .copyright {
        opacity: 0.2;
    }
}

.headerGroup {
    .flexCenter();
    display: inline-flex;

    gap: 15px;
}

.avatar {
    .flexCenter();
    color: @black-color;
    font-weight: @weight-bold;
    display: inline-flex;
    gap: 5px;
}

.avatarWrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    background-color: @white-color;
    box-shadow: 0 9px 28px 8px rgba(192, 206, 220, 0.05), 0 3px 6px -4px rgba(192, 206, 220, 0.12);
    filter: drop-shadow(0px 6px 16px rgba(192, 206, 220, 0.08));

    .sm({
        width: 50px;
        height: 50px;
    });
}


.drawer {
    display: block;

    :global {
        .ant-drawer-header-title {
            flex-direction: row-reverse;
            align-items: flex-start;
        }

        .ant-drawer-content {
            border-radius: 0 8px 8px 0;
            background-color: @background-alt;
        }

        .ant-drawer-header {
            padding: 16px 12px;
            border-bottom: none;
            background-color: @background-alt;
        }

        .ant-drawer-close {
            margin-right: 0;
        }

        .ant-drawer-body {
            padding: 0;
        }
    }
}

.drawerAvatarUserName {
    > span {
        display: block;

        &:first-child {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            color: @black-color;
        }

        &:last-child {
            font-size: 16px;
            line-height: 24px;
            color: @muted-color;
        }
    }
}

.wrapper {
  .toggleNavigation {
    position: fixed;
    bottom: 0;
    left: 10px;
    z-index: 1000;
    background: @white-color;
    color: @text-color-secondary;
    opacity: 0;
    padding: 0;
    height: 24px;
    max-width: 24px;
    transition: none;
    transform: translate(@menu-width-expanded - 20, 50%);
    .tinyShadows();

    &Compact {
      transform: translate(@menu-width-collapsed - 20, 50%);
    }

    &:hover {
      opacity: 1;
    }
  }
}


