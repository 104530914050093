// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
.wrapper {
}

.badge {
    margin-left: 5px;
}

.icon {
    i {
        margin-right: 5px;
    }

}

.dot {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 8px;
    height: 8px;
    margin-right: 4px;

    border-radius: 50%;

    &Success {
        background-color: @success-color;
    }
    &Danger {
        background-color: #D63636;
    }
  }
