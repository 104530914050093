// Select
.ant-select {
    &:not([style*="width"]) {
        min-width: 160px; // set default width
    }
}

// multiple
.ant-select-multiple {
    .ant-select-selection-item-content {
        // Important!!! use any* wrappers for label & description!
        > div + div {
            display: none; // remove selected option whitespace
        }
    }

    .ant-select-selection-item {
        background: white;
        border: none;

        // NOTE: Debug it!
        .ant-select-selection-item-content {
            > div {
                max-width: 140px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

div.ant-select-dropdown {
    .default-shadows();

}
