// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.table {
  tbody {
    vertical-align: top;
  }
}

.title {
  font-weight: 500;
  margin-bottom: 4px;
}

.description {
  color: @muted-color;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
}

.button {
  color: @primary-color;
}
