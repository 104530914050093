// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.title {
    color: @link-color;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    word-break: break-word;
}

.headingRight {
    margin-left: 8px;
}

.headingLeft {
    display: flex;
    gap: 6px;
}

.infoButton,
.tooltip {
    position: relative;
    z-index: 1;
}

.description {
    padding: 5px 0;
    color: @text-color-secondary;
    font-size: 12px;
    line-height: 1.5;
}

.heading {
    margin-bottom: 8px;
}

.headingMain {
    display: flex;
    justify-content: space-between;

}

.headingDate {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: @muted-color;

    > span:first-child {
        font-weight: 400;
    }
}

.inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // justify-content: space-between;
}

.card {
    user-select: none;
    height: 100%;
    transition: box-shadow 0.25s;

    &:not(.noLink) {
        .hover({
            box-shadow: 0 9px 28px 8px rgba(124, 157, 191, 0.1), 0 9px 16px rgba(124, 157, 191, 0.12), 0 3px 6px -4px rgba(124, 157, 191, 0.15);
            backdrop-filter: blur(16px);
        });
    }

  &.typeSecondary {
    border-radius: 0;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid @border-color-alt;

    &:last-child {
      border-bottom: none;
    }
  }
}

.entityLink {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



.noLink {
    .title {
        color: @black-color;
    }
}

