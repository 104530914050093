// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../../styles/core/_variables.app";
@import "../../../../styles/core/_mixins";
@import "../../../../styles/core/_animations";
// Page
.page {
  &.defaultLayout {
  }
}

// Greedy main content
.main {
  // Required
  display: flex;
  flex-flow: column;
  flex: 1; // *
  // Extra
  gap: 15px;
  min-width: 400px;
  align-items: flex-start;
}

// flow
.rowFlow {
  flex-flow: row;
}

.columnFlow {
  flex-flow: column;
}
