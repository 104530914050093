// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.title {
  font-size: 16px;
  font-weight: 600;
  white-space: normal;
  word-break: break-word;
  padding-right: 20px;
}

.header {
  display: grid;
  gap: 6px;
}

.relatedTitle {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
  text-align: left;
}

.related {
  text-align: left;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  .xs({
    grid-template-columns: 1fr;
  })
}

.historicMark {
  margin-right: 6px;
}

.relatedWrap {
  padding-bottom: 16px;
  border-bottom: 1px solid #f3f4f7;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
}

.askBtnWrap {

  padding-top: 16px;

  .sm({
    padding-bottom: 50px;
  })


}
