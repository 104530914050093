// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
.wrapper {
    word-break: break-word;
    white-space: break-spaces;

    :global {
        .bf-content {
            background-color: white;
            box-shadow: inset 1px 1px rgba(@border-color-base, 0.5);
            margin-bottom: 1rem;
        }
    }
}

.link {
    color: var(--link-color);
    text-decoration: 1px rgba(var(--link-color), 0.2) underline;
}
