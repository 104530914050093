// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.card {
  :global {
    .ant-card-head {
      padding: 12px 16px;
    }
    .ant-card-head-title {
      padding: 0;
    }
    .ant-card-body {
      &::before, &::after {
        display: none;
      }
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.headerRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  flex-wrap: wrap;
}

.header {
  display: grid;
  grid-gap: 8px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  white-space: normal;
  word-break: break-word;
}

.date {
  font-size: 13px;
}

.description {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  margin-bottom: 12px;
}

.btnWrap {
  text-align: right;

  :global {
    .ant-btn {
      .xs({
        width: 100%;
      })
    }
  }

}
