// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
// CustomerTimezoneSelect
.wrapper {
    .flexCenter();
    cursor: pointer;
    padding-top: 2px;
}

.clock {
    font-size: 13px;
    //opacity: .65;

    .sm({
        font-size:  12px;
        color: @muted-color;
    })
}
