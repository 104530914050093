// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.wrapper {
}

.ico {
}

.description {
    margin-left: 5px;
}

.tooltip {
}

.text {
    margin-left: 4px;
}

.clickable {
    cursor: pointer;
}
