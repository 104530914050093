// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../styles/core/_variables.app";
@import "../../styles/core/_mixins";
@import "../../styles/core/_animations";
// Currency
.wrapper {
    //display: inline-flex;
}

.currency {
}

.prefix {
    padding-right: 0.5ch;
}

.suffix {
    padding-left: 0.5ch;
}

.negative {
    color: @danger-color;
}

.positive {
    color: @success-color;
}

.primary {
    color: @primary-color;
}

.values {
    display: inline-flex;
    justify-content: flex-end;
    white-space: nowrap;

    .data + .data {
        &::before {
            content: "•";
            opacity: 0.2;
            padding: 0 4px;
        }
    }
}
