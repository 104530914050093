// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
.menuShadows() {
    box-shadow: 0 1px 1px rgba(lighten(@black-color, 25%), 0.1);
}

.wrapper {
    margin-top: 2px;
    padding: 3px;

    .sm({
        margin-top: 0;
        padding-top: 0;
    })
}

.item {
    font-size: 0.9rem;
    margin: 0.5rem 0.5rem;
}

.itemSearch {
    .sm({
        margin-top: 0;
    })
}

.collapseTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collapseList {
  padding-left: 24px;
}

.link {
    flex: 1;
    padding: 0.25rem 0.3rem;
    transition: @transition;
    color: lighten(@black-color, 15%);
    border-radius: @border-radius;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    display: block;
    .flexCenter;
    justify-content: flex-start;

    .sm-min({
      .compact & {
        .tinyShadows();
        .flexCenter();

        padding: 0;
        max-width: 28px;
        max-height: 28px;
        margin-bottom: 15px;
        margin-left: auto;
        margin-right: auto;

        .icon {
          i {
            .size(31px);
            margin: 0;
          }
        }



        &:hover .text {
          // show
          transform: translateX(35px);
          opacity: 1;
          visibility: visible;
        }
      }
    });



    &Inactive {
      cursor: default;
    }

    > span {
      display: flex;
      align-items: center;
    }

    &:hover {
        color: #000;
    }

    span:not(.collapseIcon) {
      > i {
        .size(26px);
        .flexCenter;
        .menuShadows;
        &:extend(.tiny-shadows);
        font-size: 1rem;
        font-weight: 300; // thin icons
        display: inline-flex;
        margin-right: 8px;
        transition: all 0.15s ease;
        color: lighten(@black-color, 25%);
        border-radius: @border-radius;
        background: #fff;

      }

    }

    &.active {
        .tinyShadows;
        color: @black-color;
        background: linear-gradient(90deg, white, rgba(white, 0.4));



      span {
        > i {
          box-shadow: 0 3px 6px rgba(@primary-color, 0.5);
          background: @primary-color;
          color: @white-color;
          font-weight: bold; // filled icons
          transform: scale(1.2);
          text-shadow: 1px 1px 1px rgba(black, 0.1);
        }
      }

      .sm-min({
        .compact & {

          span {
            > i {
              transform: scale(1.05);
            }
          }
        }
      });





    }

    // Focus marker (onTab)
    &:focus:not(.active):not(:active)::before {
        .size(4px);
        content: "";
        border-radius: 5px;
        background: @primary-color;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.text {
  .textOverflow();

  max-width: 135px;
  transition: none;

  .sm-min({
    .compact & {
      .tinyShadows();
      padding: 0 4px;
      border-radius: 4px;

      overflow: visible;
      max-width: unset;
      position: absolute;
      left: 0;
      z-index: 1000;
      background: white;
      // hide
      will-change: transform;
      transition: transform 0.2s ease;
      transform: translateX(20px);
      box-shadow: 0 3px 8px rgba(@muted-color, 0.2);
      opacity: 0;
      font-size: 12px;
      visibility: hidden;
    }
  })
}

//.menuGroupWrap {
//  .sm-min({
//    .compact & {
//     width: 30px;
//    }
//  })
//}

.menuGroupName {
  color: @muted-color;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  padding-left: 14px;
  margin: 20px 0 10px;

  .sm-min({
    .compact & {
      font-size: 10px;
      padding-left: 0;
      text-align: center;
    }});
}

.menuGroupWrap:first-child {
  .menuGroupName {
    .sm-min({
      .compact & {
        margin-top: 10px;
      }
    });

  }
}
