// Antd defaults (TEST: could potentially be a recursion)
@import "~antd/lib/style/themes/default.less";

// Core
@import "../../../styles/core/_variables.app";
@import "../../../styles/core/_mixins";
@import "../../../styles/core/_animations";
// Button

.loading {
}

button {
    // ButtonModifications (only background & colors)
    // * Please, sync it with
    // -> types/common/components/ButtonModifications.ts

    &.writer {
        .addButtonVariant(@writer-color, white);
    }

    // Roles
    &.writerOutlined {
        .addButtonOutlined(@writer-color);
    }

    &.customer {
        .addButtonVariant(@customer-color, white);
    }

    // Colors

    &.customerOutlined {
        .addButtonOutlined(@customer-color);
    }

    &.green {
        .addButtonVariant(@success-color, white);
    }

    &.greenOutlined {
        .addButtonOutlined(@success-color);
    }

    &.purple {
        .addButtonVariant(@pending-color, white);
    }

    &.purpleOutlined {
        .addButtonOutlined(@pending-color);
    }

    &.orange {
        .addButtonVariant(@warning-color, white);
    }

    &.orangeOutlined {
        .addButtonOutlined(@warning-color);
    }

    &.blue {
        .addButtonVariant(@info-color, white);
    }

    &.blueOutlined {
        .addButtonOutlined(@info-color);
    }

    &.red {
        .addButtonVariant(@danger-color, white);
    }

    &.redOutlined {
        .addButtonOutlined(@danger-color);
    }

    &.primary {
        .addButtonVariant(@primary-color, white);
    }

    &.primaryOutlined {
        .addButtonOutlined(@primary-color);
    }

    &.cyan {
        .addButtonVariant(#fff, white);
    }

    &.cyanOutlined {
        .addButtonOutlined(#fff);
    }

    &.grayOutlined {
        .addButtonOutlined(@color-gray);
        background: transparent;

        &:hover {
            background: transparent;
        }
    }

    // By size

    &.small {
    }

    &.default {
    }

    &.large {
    }

    // Content dependent
    &.iconOnly {
        width: 32px; // default
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &.small {
            .size(24px);
        }

        &.large {
            .size(40px);

            > span {
                display: flex; // center icon
                // icon
                > i {
                    font-size: 18px;
                }
            }
        }
    }

    &.hasChildren {
        // icon + children
        span + span {
            padding-left: 4px;
        }
    }
}
